import { extractImageUrl, extractPNGImageUrl } from "./../utils/glance";

import { requester as axios } from "./axios-client";
import { webpSupport } from "./../utils/browser";
import { getLanguagePreference } from "../utils/glance";

export async function getGlanceIds({ nodeRequest } = {}) {
  const obj = {};
  const headers =
    nodeRequest && nodeRequest.headers.cookie
      ? { Cookie: nodeRequest.headers.cookie }
      : undefined;

  if (headers) {
    obj.headers = headers;
  }

  const region =
    nodeRequest && nodeRequest.query && nodeRequest.query.region
      ? nodeRequest.query.region
      : undefined;

  const countryCode =
    nodeRequest && nodeRequest.userCountryCode
      ? nodeRequest.userCountryCode
      : undefined;

  const language =
    nodeRequest && nodeRequest.query && nodeRequest.query.language;
  let langPreference = [];
  if (language) {
    langPreference = getLanguagePreference(language.substring(0, 2));
  }
  const query = nodeRequest && nodeRequest.query ? nodeRequest.query : {};

  const sportsCategoryOnly =
    nodeRequest && nodeRequest.url.includes("sports") ? true : false;
  const fromMarketingPage =
    nodeRequest && nodeRequest.url.includes("marketing") ? true : false;

  const resp = await axios({
    method: "get",
    url: "/glances/ids",
    ...obj,
    responseType: "json",
    params: {
      ...query,
      countryCode,
      region,
      langPreference,
      sportsCategoryOnly: sportsCategoryOnly,
      fromMarketingPage: fromMarketingPage,
    },
  });
  return resp.data && resp.data.length
    ? resp.data.filter((g) => g.glanceType !== "WALLPAPER")
    : [];
}

export async function getBubbleIds({ nodeRequest, id } = {}) {
  const resp = await axios({
    method: "get",
    url: "/stories/" + id,
    headers:
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined,
    responseType: "json",
  });
  return resp.data && resp.data.length ? resp.data : [];
}

function _getGlances(url) {
  return async function({ nodeRequest, ids } = {}) {
    const obj = {};
    const headers =
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined;

    if (headers) {
      obj.headers = headers;
    }

    const _region =
      nodeRequest && nodeRequest.query && nodeRequest.query.region
        ? nodeRequest.query.region
        : undefined;

    const _countryCode =
      nodeRequest && nodeRequest.userCountryCode
        ? nodeRequest.userCountryCode
        : undefined;

    const countryCode = _region || _countryCode;

    const apiKey =
      nodeRequest && nodeRequest.query && nodeRequest.query.apiKey
        ? nodeRequest.query.apiKey
        : undefined;

    const utm_source =
      nodeRequest && nodeRequest.query && nodeRequest.query.utm_source
        ? nodeRequest.query.utm_source
        : undefined;

    const resp = await axios({
      method: "get",
      url,
      ...obj,
      responseType: "json",
      params: {
        ids,
        countryCode,
        apiKey,
        utm_source,
      },
    });
    const iswebsupported = await webpSupport;
    return resp.data && resp.data.length
      ? resp.data.reduce((agg, glance) => {
          if (glance.image) {
            // we don't show glance without image
            const imgUrl = extractImageUrl(glance.image.supportedImages);
            const overlayImgUrl = glance.overlayImage
              ? extractImageUrl(glance.overlayImage.supportedImages)
              : null;
            glance.image.url = iswebsupported
              ? imgUrl
              : imgUrl.replace(".webp", ".jpg");
            if (overlayImgUrl) {
              glance.overlayImage.url = iswebsupported
                ? overlayImgUrl
                : extractPNGImageUrl(glance.overlayImage.supportedImages);
            }
            agg.push(glance);
          }
          return agg;
        }, [])
      : [];
  };
}

export const getGlances = _getGlances("/glances/detail");
export const getBubbles = _getGlances("/bubbles/detail");
export const getDemoGlances = _getGlances("/demo-glances/detail");

export async function getGlanceById({ nodeRequest, id } = {}) {
  const url = `/glances/${id}`;
  const query = nodeRequest && nodeRequest.query ? nodeRequest.query : {};
  const resp = await axios({
    method: "get",
    url,
    headers:
      nodeRequest && nodeRequest.headers.cookie
        ? { Cookie: nodeRequest.headers.cookie }
        : undefined,
    responseType: "json",
    params: {
      ...query,
    },
  });
  const iswebsupported = await webpSupport;

  const glance = resp.data;
  if (glance && glance.image) {
    // we don't show glance without image
    const imgUrl = extractImageUrl(glance.image.supportedImages);
    const overlayImgUrl = glance.overlayImage
      ? extractImageUrl(glance.overlayImage.supportedImages)
      : "";
    glance.image.url = iswebsupported
      ? imgUrl
      : imgUrl.replace(".webp", ".jpg");
    if (overlayImgUrl) {
      glance.overlayImage.url = iswebsupported
        ? overlayImgUrl
        : extractPNGImageUrl(glance.overlayImage.supportedImages);
    }
  }
  return glance;
}

export async function updateOnboarding() {
  const resp = await axios({
    method: "post",
    url: "/glances/onboarding",
  });
  return resp.data;
}
